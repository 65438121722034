import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NotFound from './NotFound';
import MintPage from './pages/Mint';
import MainPage from './pages/Main';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';



function getLibrary(provider) {
  return new Web3(provider)
}

//---------------------------------------------------------------------------------//
function App() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
    <Router>
      <Switch>
        <Route path= "/" exact component={MainPage} />
        <Route path="/mint" exact component={MintPage} />
        <noindex><Route component={NotFound} /></noindex>
      </Switch>
    </Router>
    </Web3ReactProvider>
  );
}
//----------------------------------------------------------------------------------//
export default App;
