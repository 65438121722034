
const Sec3 =() => {
  return (
    <section className="section-monkey">
  <div className="one-monkey" style={{backgroundImage: 'url("img/m-1.png")'}}>
  </div>
  <div className="one-monkey" style={{backgroundImage: 'url("img/m-2.png")'}}>
    <div className="over-red" />
  </div>
  <div className="one-monkey" style={{backgroundImage: 'url("img/m-3.png")'}}>
    <div className="over-yellow" />
  </div>
  <div className="one-monkey" style={{backgroundImage: 'url("img/m-4.png")'}}>
    <div className="over-blue" />
  </div>
  <h1 className="big-monkey-title animate-charcter">
    MONKEY GOD 
  </h1>
</section>
  )
}

export default Sec3;