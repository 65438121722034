import Rocket from '../img/rocket.png';

const Sec5 =() => {
  return (
    <section className="section-roadmap" id="roadmap">
  <div className="the-rocket">
    <img src={Rocket} />
  </div>
  <div className="container">
    <h2 className="border-title text-center">
      ROADMAP
    </h2>
    <div className="road-map-box">
      <div className="road-item wow fadeInUp">
        <div className="text-box">
          <p>
            First Part<br />
            Promote and market<br />
            Monkey God NFT collection.
          </p>
        </div>
        <div className="circle">
          1
        </div>
        <div className="line">
          <div className="the-line" />
        </div>
      </div>{/* item */}
      <div className="road-item wow fadeInUp">
        <div className="text-box">
          <p>
            To us, the community comes first. The main goal is to make sure your investment and our mint are as successful as possible, to achieve all of our goals. We will also constantly work to release the best value and quality possible. 
          </p>
        </div>
        <div className="circle">
          2
        </div>
        <div className="line">
          <div className="the-line" />
        </div>
      </div>{/* item */}
      <div className="road-item wow fadeInUp">
        <div className="text-box">
          <p>
            Launch NFT collection. <br />
            Start Minting on <br />
            <a href="#">www.monkeygod.io</a> <br />
            Available on <br />
            <a href="#">www.opensea.io</a>
          </p>
        </div>
        <div className="circle">
          3
        </div>
        <div className="line">
          <div className="the-line" />
        </div>
      </div>{/* item */}
      <div className="road-item wow fadeInUp">
        <div className="text-box">
          <p>
            ist Monkey God NFT collection on<br />
            rarity.tools<br />
            <a href="#">https://dappradar.com/</a> <br />
            <a href="#">ww.nft-stats.com</a> <br />
            <a href="#">https://upcomingnft.net</a> <br />
            <a href="#">https://nftevening.com</a> <br />
          </p>
        </div>
        <div className="circle">
          4
        </div>
        <div className="line">
          <div className="the-line" />
        </div>
      </div>{/* item */}
      <div className="road-item wow fadeInUp">
        <div className="text-box">
          <p>
            Buy land on the best metaverse. <br />
            Develop Monkey God on popular metaverse like Decentraland, Sandbox.
            At least one metaverse will be picked to built Monkey God land.
          </p>
        </div>
        <div className="circle">
          5
        </div>
        <div className="line">
          <div className="the-line" />
        </div>
      </div>{/* item */}
      <div className="road-item wow fadeInUp">
        <div className="text-box">
          <p>
            Launch exclusive events on the Monkey God land. <br />
            The Monkey God community members will get an access to private events on the Monkey God metaverse.
          </p>
        </div>
        <div className="circle">
          6
        </div>
        <div className="line">
          <div className="the-line" />
        </div>
      </div>{/* item */}
    </div>
  </div>
</section>

  )
}

export default Sec5;