import Header_mint from '../Header_mint';
//import Footer from '../Footer';
import Mint from '../components/mint';

const MintPage = () => {
    return(     
        <div>
        <Header_mint />
        <section className="section-mint">
        <div className="container">
          <div className="mint-cover">
            <div className="mint-circle-1" />
            <div className="mint-circle-2" />
            <div className="mint-box wow fadeInUp">
              <Mint />
            </div>
          </div>
        </div>
      </section>
        </div>
    )
};

export default MintPage;
