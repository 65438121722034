import bottom_monkey from '../img/bottom-monkey.gif';
import item_1 from '../img/item-1.png';
import item_2 from '../img/item-2.png';
import item_3 from '../img/item-3.png';
import item_4 from '../img/item-4.png';
import item_5 from '../img/item-5.png';

const Sec4 =() => {
  return (
    <section className="section-plain">
  <div className="container text-center">
    <h2 className="border-title wow fadeInUp">
      REWARDS
    </h2>
    <div className="reward-content">
      <p className="font-weight-bold wow fadeInUp">
        The holders will get access to our community funds used <br />
        to ​​invest, reward, and collaborate with the best projects <br />
        in the space and projects ideas of our members.
      </p>
      <p className="wow fadeInUp">
        Private Land on the Metaverse where only our community can have access.<br />
        If you are an investor who wants high returns. Our NFTs are built with believe and <br />
        rarity&nbsp; that will give you a return that you will be satisfied.
      </p>
    </div>
    <div className="monkey-block wow fadeInUp">
      <img src={bottom_monkey} />
    </div>
    <h2 className="border-title wow fadeInUp">
      CHASE 10 LEGENDARY
    </h2>
    <h3 className="main-title slide-title wow fadeInUp">
      MONKEY GOD - THEY ARE THE RAREST   
    </h3>
  </div>
  <div className="collection-slide">
    {/* Swiper */}
    <div className="swiper-container collection-swiper">
      <div className="swiper-wrapper">
        <div className="swiper-slide slide-item">
          <a href="#" className="item-overlay">
            <div className="text">
              <h3>Monkey gold</h3>
              <p>
                Charecter 4 &gt;
              </p>
            </div>
          </a>
          <img src={item_1} />
        </div>{/* slide */}
        <div className="swiper-slide slide-item">
          <a href="#" className="item-overlay">
            <div className="text">
              <h3>Monkey gold</h3>
              <p>
                Charecter 4 &gt;
              </p>
            </div>
          </a>
          <img src={item_2} />
        </div>{/* slide */}
        <div className="swiper-slide slide-item">
          <a href="#" className="item-overlay">
            <div className="text">
              <h3>Monkey gold</h3>
              <p>
                Charecter 4 &gt;
              </p>
            </div>
          </a>
          <img src={item_3} />
        </div>{/* slide */}
        <div className="swiper-slide slide-item">
          <a href="#" className="item-overlay">
            <div className="text">
              <h3>Monkey gold</h3>
              <p>
                Charecter 4 &gt;
              </p>
            </div>
          </a>
          <img src={item_4} />
        </div>{/* slide */}
        <div className="swiper-slide slide-item">
          <a href="#" className="item-overlay">
            <div className="text">
              <h3>Monkey gold</h3>
              <p>
                Charecter 4 &gt;
              </p>
            </div>
          </a>
          <img src={item_5} />
        </div>{/* slide */}
      </div>
    </div>{/* swiper container */}
    <div className="the-arrow collection-button-next">
      <i className="fas fa-arrow-right" />
    </div>
    <div className="the-arrow collection-button-prev">
      <i className="fas fa-arrow-left" />
    </div>
  </div>{/* swiper cover */}
</section>
  )
}

export default Sec4;