import Header from '../Header';
import Sec1 from '../components/sec1';
import Sec2 from '../components/sec2';
import Sec3 from '../components/sec3';
import Sec4 from '../components/sec4';
import Sec5 from '../components/sec5';
import Sec6 from '../components/sec6';
import Footer from '../Footer';


const MainPage = () => {
    return(     
        <div>
        <Header />
        <Sec1 />
        <Sec2 />
        <Sec3 />
        <Sec4 />
        <Sec5 />
        <Sec6 />
        <Footer />
        </div>
    )
};

export default MainPage;
