import bottom_circle from '../img/bottom-circle.png';

const Sec6 =() => {
  return (
    <section className="section-plain section-footer" id="faq">
  <div className="bottom-circle">
    <img src={bottom_circle} />
  </div>
  <div className="container">
    <div className="mix-title">
      <h2 className="border-title wow fadeInUp">
        FAQ
      </h2>
      <h2 className="white-title wow fadeInUp">
        HAVE QUESTIONS?
      </h2>
    </div>
    <div className="faq-cover">
      <div className="faq-item wow fadeInUp">
        <div className="qq">
          WHEN IS THE OFFICIAL LUNCH ? 
        </div>
        <div className="aa">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
        </div>
      </div>
      <div className="faq-item wow fadeInUp">
        <div className="qq">
          WHEN IS THE OFFICIAL LUNCH ? 
        </div>
        <div className="aa">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
        </div>
      </div>
      <div className="faq-item wow fadeInUp">
        <div className="qq">
          WHEN IS THE OFFICIAL LUNCH ? 
        </div>
        <div className="aa">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
        </div>
      </div>
      <div className="faq-item wow fadeInUp">
        <div className="qq">
          WHEN IS THE OFFICIAL LUNCH ? 
        </div>
        <div className="aa">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Sec6;