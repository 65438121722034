//import t_end from '../img/text-end.png';
//import Timer from './Timer';
import MintApp from './MintApp'; 



const Mint =() => {
    return (

      <div>        
         <MintApp />        
      </div>

    )
}

export default Mint
