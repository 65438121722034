import intro_img from '../img/intro-img.png';
import icon_discord from '../img/icon-discord.png';

const Sec2 =() => {
  return (
      <section className="section-intro" id="whatis">
        <div className="container">
          <div className="row aling-items-end">
            <div className="col-md-4 wow fadeInUp">
              <img src={intro_img} />
            </div>
      <div className="col-md-8">
        <h2 className="border-title wow fadeInUp">
          Monkey God
        </h2>
        <h3 className="main-title wow fadeInUp">
          Monkey God is a 3D set <br />
          designed by faith.
        </h3>
        <p className="p-content wow fadeInUp">
          There 10,000 artworks. With ERC-721 token standard runs on the Ethereum blockchain.Each Monkey God is unique, based on the Monkey God character. The objective is to create a community that shares the same belief. To maximize the strength of the NFTs project.-------------------------------------------------------------------------------------------Join the Monkey God community. You will be co-investing with a large number of investors. Full of people who believe in the future of nft and blockchain technology.
        </p>
      </div>
      <div className="col-md-8 offset-md-4">
        <a href="#" className="discord-btn view-btn wow fadeInUp">
          <img src={icon_discord} /> JOIN OUR DISCORD
        </a>
      </div>
    </div>
  </div>
</section>   
  )
}

export default Sec2;