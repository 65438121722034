import i_twitter from "./img/icon-twitter.png"
import i_opensea_f from "./img/icon-opensea-f.png"
import i_twitter_h from "./img/icon-twitter-h.png"


function Footer () {
    return (
        <div>
            <footer>
			<div class="container">
				<div class="row">
					<div class="col-md-4">
						<h2 class="border-title">
							JOIN
						</h2>
						<h3 class="main-title">
							MONKEY GOD
						</h3>
						<p>
							JOIN THE COMMUNITY NOWTOGETHER WE GOING TO BUILD THE BEST COMMUNITY IN THE NFT SPACE, LET'S SHOW THE WORLD HOW INFLUENT AND ENGAGED WE ARE WE ARE GOING TO TAKE OVER THE METAVERSE AND NFT SPACE
						</p>
						<div class="copyright">
							2022 MONKEY GOD.ALL RIGHTS RESERVED
						</div>
					</div>
					<div class="col-md-3 offset-md-2">
						<a href="#" class="join-btn">
							JOIN  MONKEY GOD  
						</a>
						<p>
							Let's come together to be a strong member and growing rapidly with us in the NFT community.
						</p>
					</div>
					<div class="col-md-3">
						<a href="#" class="footer-discord">
							<img src={i_twitter_h}/>
							JOIN OUR TWITTER
						</a>
						<div class="social-pack">
							<h4>FOLLOW US</h4>
							<ul>
								<li><a href="#"><img src={i_twitter}/></a></li>
                                <li><a href="#"><img src={i_opensea_f}/></a></li>

							</ul>
						</div>
						<div class="mobile-copyright">
							2022 MONKEY GOD.ALL RIGHTS RESERVED
						</div>
					</div>
				</div>	
			</div>
		</footer>
        </div>
    )
}

export default Footer;