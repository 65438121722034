import logo from "./img/logo.png"
import i_twitter_m from "./img/icon-twitter-mobile.png"
import i_twitter_h from "./img/icon-twitter-header.png"
import i_opensea from "./img/icon-opensea.png"

function Header_mint () {
    return (
        <div>
            <header>
          <div className="container">
            <div className="top-menu">
              <a href="/" className="logo">
                <img src={logo} />
              </a>
              <div className="main-menu">
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/mint">MINT</a></li>
                </ul>
              </div>
              <div className="button-header">
                <div className="burger-icon">
                  <div className="burger-line burger-line-1" />
                  <div className="burger-line burger-line-2" />
                  <div className="burger-line burger-line-3" />
                </div>
                <a href="#" className="discord-mobile">
                  <img src={i_twitter_m} />
                </a>
                <a href="#" className="header-join-btn">
                  <img src={i_twitter_h} /> JOIN OUR TWiTTER
                </a>
                <a href="#" className="join-btn">
                  <img src={i_opensea} /> Buy On Opensea
                </a>
              </div>
            </div>
          </div>
        </header>	
        <div className="burger-menu">
          <ul className="burger-m">
            <li><a href="/">Home</a></li>
            <li><a href="/mint">MINT</a></li>
          </ul>
        </div>
        </div>
    )
}

export default Header_mint;