import top_monkey from '../img/top-monkey.gif';

const Sec1 =() => {
  return (
      <section className="section-top" id="home">
  <img src={top_monkey} className="top-monkey" />
  <div className="top-overlay">
    <div className="text-box">
      <div className="mix-title">
        <h2 className="border-title wow fadeInUp">JOIN</h2>
        <h2 className="gold-title wow fadeInUp">MONKEY GOD</h2>
      </div>
      <p className="wow fadeInUp">
        Join exclusive and unique NFT project. It’s not just an art, but a large-scale venture that <br />
        delivers rewards beyond imagination.
      </p>
      <a href="#" className="view-btn wow fadeInUp">
        VIEW ALL COLLECTION
      </a>
    </div>
  </div>
</section>  
  )
}

export default Sec1;